import "./styles/global/main.scss"; //Get regular scss
require(`./styles/${process.env.VUE_APP_NAME}/main.scss`); //Get site specific scss

import "./components";

import dayjs from "dayjs";
import "dayjs/locale/nl";
import "dayjs/locale/en";

const lang = document.documentElement.getAttribute("lang");
dayjs.locale(lang);
